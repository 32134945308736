import { Breakpoint, Components, Theme } from '@mui/material'

export const CONTAINER_GUTTERS: Record<Breakpoint, number> = {
  xs: 2,
  sm: 3,
  md: 4,
  lg: 10,
  xl: 22,
}

export const ContainerTheme: Components<Theme> = {
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xl',
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const gutterStyles = Object.entries(CONTAINER_GUTTERS).reduce(
          (result, [breakpoint, value]) => ({
            ...result,
            [theme.breakpoints.up(breakpoint as Breakpoint)]: {
              paddingLeft: theme.spacing(value),
              paddingRight: theme.spacing(value),
            },
          }),
          {},
        )

        return ownerState.disableGutters ? {} : gutterStyles
      },
    },
  },
}
