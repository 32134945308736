import { Components, Theme } from '@mui/material'

export const LinkTheme: Components<Theme> = {
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
    styleOverrides: {
      underlineHover: {
        position: 'relative',
        '&::after': {
          transition: 'transform 200ms ease',
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '0.07em',
          bottom: '0.07em',
          transform: 'scaleX(0)',
          backgroundColor: 'currentColor',
        },
        '&:hover': {
          textDecoration: 'none',
          '&::after': {
            transform: 'scaleX(1)',
          },
        },
      },
    },
  },
}
