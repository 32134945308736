import EventRounded from '@mui/icons-material/EventRounded'
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import { Components } from '@mui/material'

export const DateAndTimePickersTheme: Components = {
  MuiDatePicker: {
    defaultProps: {
      components: {
        OpenPickerIcon: EventRounded,
        SwitchViewIcon: KeyboardArrowDownRounded,
      },
    },
  },
  MuiDateTimePicker: {
    defaultProps: {
      components: {
        OpenPickerIcon: EventRounded,
      },
    },
  },
  MuiCalendarPicker: {
    defaultProps: {
      components: {
        SwitchViewIcon: KeyboardArrowDownRounded,
      },
    },
  },
}
