import { Components, Theme } from '@mui/material'

export const ButtonGroupTheme: Components<Theme> = {
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 100,
      },
    },
  },
}
