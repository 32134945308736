import { Components, Theme } from '@mui/material'

export const CardTheme: Components<Theme> = {
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'subtitle2',
      },
    },
  },
}
