import { ThemeOptions } from '@mui/material'

export const PaletteOptions: ThemeOptions['palette'] = {
  primary: { main: '#61C685', contrastText: '#FFF' },
  secondary: { main: '#3F4144' },
  success: { main: '#61C685', contrastText: '#FFF' },
  warning: { main: '#962446' },
  info: { main: '#FFCC37', contrastText: '#3f4244' },
  error: { main: '#FF636D', contrastText: '#FFF' },
  inverted: {
    main: '#FFF',
    dark: '#BDBDBD',
    light: '#FFF',
    contrastText: '#3F4144',
  },
  text: { primary: '#3F4144', secondary: '#6B8083', disabled: '#96A3A5' },
  background: { default: '#FAFAFA' },
  common: { black: '#3F4244' },
}

export const EatFirstPaletteOptions: ThemeOptions['palette'] = {
  primary: { main: '#3138B4' },
  secondary: { main: '#941F81' },
  success: { main: '#00E882', contrastText: '#1E236E' },
  warning: { main: '#BF9D00', contrastText: '#FFF' },
  info: { main: '#0067C5' },
  error: { main: '#E50303' },
  inverted: {
    main: '#FFF',
    dark: '#BDBDBD',
    light: '#FFF',
    contrastText: '#212932',
  },
  text: { primary: '#212932', secondary: '#55606C', disabled: '#9CA5B0' },
  background: { default: '#FAFAFA' },
  common: { black: '#3F4244' },
}
