import { accordionClasses, Components, Theme } from '@mui/material'

export const AccordionTheme: Components<Theme> = {
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
    },
    styleOverrides: {
      root: {
        [`&.${accordionClasses.expanded}:before`]: {
          opacity: 1,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: ({ theme }) => ({
        margin: `${theme.spacing(3)} 0`,
      }),
      expandIconWrapper: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
}
