import { Components, Theme } from '@mui/material'
import { match } from 'ts-pattern'

type MuiButtonTheme = NonNullable<Components<Theme>['MuiButton']>
type StyleOverrides = NonNullable<MuiButtonTheme['styleOverrides']>

const createOutlinedBackgroundStyles: StyleOverrides['outlined'] = ({
  ownerState,
  theme,
}) =>
  ownerState.color === 'inverted'
    ? {}
    : {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      }

export const ButtonTheme: Components<Theme> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: match(theme.brand)
          .with('Feedr', () => 100)
          .with('EatFirst', () => 4)
          .exhaustive(),
      }),
      outlined: (...args) => createOutlinedBackgroundStyles(...args),
    },
  },
}
