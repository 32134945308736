import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import { Components } from '@mui/material'

export const SelectTheme: Components = {
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
      IconComponent: KeyboardArrowDownRounded,
    },
  },
}
