import { Components, createTheme, Theme, ThemeOptions } from '@mui/material'

/**
 * We need to set typography per breakpoint, but there is no easy way to access them here.
 * This workaround will only work as long as we use default MUI breakpoints,
 * hence if we ever set our own breakpoints we should revisit this.
 */
const { breakpoints } = createTheme()

export const TypographyOptions: ThemeOptions['typography'] = (palette) => ({
  fontFamily: ['var(--montserrat-font, Montserrat)', 'sans-serif'].join(','),
  allVariants: { color: palette.text.primary },
  h0: {
    fontSize: 64,
    lineHeight: 96 / 64,
    fontWeight: 700,
    letterSpacing: -1,
    [breakpoints.up('md')]: {
      fontSize: 96,
      lineHeight: 112 / 96,
    },
  },
  h1: {
    fontSize: 48,
    lineHeight: 64 / 48,
    fontWeight: 700,
    letterSpacing: -1,
    [breakpoints.up('md')]: {
      fontSize: 64,
      lineHeight: 96 / 64,
    },
  },
  h2: {
    fontSize: 32,
    lineHeight: 48 / 32,
    fontWeight: 700,
    letterSpacing: -0.5,
    [breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: 64 / 48,
    },
  },
  h3: {
    fontSize: 24,
    lineHeight: 32 / 24,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 32,
      lineHeight: 48 / 32,
    },
  },
  h4: {
    fontSize: 18,
    lineHeight: 26 / 18,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: 32 / 24,
    },
  },
  h5: {
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 18,
      lineHeight: 26 / 18,
    },
  },
  h6: {
    fontSize: 14,
    lineHeight: 20 / 14,
    fontWeight: 700,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: 24 / 16,
    },
  },
  subtitle1: {
    fontSize: 18,
    lineHeight: 26 / 18,
    fontWeight: 600,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: 32 / 24,
    },
  },
  subtitle2: {
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 600,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 18,
      lineHeight: 26 / 18,
    },
  },
  subtitle3: {
    fontSize: 14,
    lineHeight: 22 / 14,
    fontWeight: 600,
    letterSpacing: 0,
    [breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: 24 / 16,
    },
  },
  body1: {
    fontSize: 16,
    lineHeight: 24 / 16,
    fontWeight: 500,
    letterSpacing: 0,
  },
  body2: {
    fontSize: 14,
    lineHeight: 22 / 14,
    fontWeight: 500,
    letterSpacing: 0,
  },
  button: {
    fontSize: 14,
    lineHeight: 24 / 14,
    fontWeight: 600,
    letterSpacing: 0,
    textTransform: 'none',
  },
  caption: {
    fontSize: 12,
    lineHeight: 18 / 12,
    fontWeight: 500,
    letterSpacing: 0,
  },
  overline: {
    fontSize: 10,
    lineHeight: 12 / 10,
    fontWeight: 600,
    letterSpacing: 0,
  },
})

export const EatFirstTypographyOptions: ThemeOptions['typography'] = (
  palette,
) => ({
  ...TypographyOptions(palette),
})

export const TypographyTheme: Components<Theme> = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h0: 'h1',
        subtitle3: 'h6',
      },
    },
  },
}
