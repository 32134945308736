import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import { Components } from '@mui/material'
import React from 'react'

export const AutocompleteTheme: Components = {
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <KeyboardArrowDownRounded />,
    },
  },
}
