'use client'

import React, { forwardRef } from 'react'
import { CssBaseline, ThemeProvider, createTheme, lighten, stepLabelClasses } from '@mui/material'
import { muiTheme } from '@caterdesk/ui--mui-theme'
import { colors } from '@/constants/theme'
import useTenant from '@/hooks/useTenant'
import { main as legacyTheme } from '@caterdesk/ui--theme'
import { ThemeProvider as LegacyThemeProvider } from 'styled-components'
import Link from '@/components/Link'
import { TenantFromPrebuildFragment } from '@/generated/graphql'

const LinkBehaviour = forwardRef(function LinkBehaviour(props, ref) {
  // @ts-expect-error
  return <Link ref={ref} {...props} />
})

function removeNulls(obj: any): any {
  if (obj === null || obj === undefined) return undefined

  if (Array.isArray(obj)) {
    return obj.map(removeNulls)
  }

  if (typeof obj === 'object') {
    return Object.entries(obj)
      .filter(([_, value]) => value !== null)
      .reduce((newObj, [key, value]) => {
        newObj[key] = removeNulls(value)
        return newObj
      }, {} as any)
  }

  return obj
}

const FeedrThemeProvider: React.FC<{
  children: React.ReactNode
  tenantOverride?: TenantFromPrebuildFragment
}> = ({ children, tenantOverride }) => {
  const tenant = useTenant()

  const selectedTenant = tenantOverride || tenant

  const combinedPalette = selectedTenant.marketing.theme?.palette
    ? removeNulls(selectedTenant.marketing.theme.palette)
    : muiTheme.palette

  const selectedTheme = createTheme({
    ...muiTheme,
    palette: combinedPalette,
    typography: {
      ...muiTheme.typography,
      allVariants: { color: combinedPalette.text.primary },
    },
  })

  const customisedMuiTheme = {
    ...selectedTheme,
    components: {
      ...selectedTheme.components,
      MuiLink: { defaultProps: { component: LinkBehaviour } },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontSize: 16,
            [`&.${stepLabelClasses.active}:not(.${stepLabelClasses.error})`]: {
              color: selectedTheme.palette.primary.main,
            },
          },
        },
      },
    },
    palette: {
      ...selectedTheme.palette,
      ...colors,
    },
  }

  const customisedLegacyTheme = {
    ...legacyTheme,
    colors: {
      ...legacyTheme.colors,
      primary: selectedTheme.palette.primary.main,
      primary_dark: selectedTheme.palette.primary.dark,
      primary_light: selectedTheme.palette.primary.light,
      primary_lightest: lighten(selectedTheme.palette.primary.main, 0.8),
      primary_contrastText: selectedTheme.palette.primary.contrastText,
      special: selectedTheme.palette.secondary.main,
      special_dark: selectedTheme.palette.secondary.dark,
      special_light: selectedTheme.palette.secondary.light,
      special_lightest: lighten(selectedTheme.palette.secondary.main, 0.8),
      success: selectedTheme.palette.success.main,
      success_dark: selectedTheme.palette.success.dark,
      success_light: selectedTheme.palette.success.light,
      success_lightest: lighten(selectedTheme.palette.success.main, 0.8),
      info: selectedTheme.palette.info.main,
      info_dark: selectedTheme.palette.info.dark,
      info_light: selectedTheme.palette.info.light,
      info_lightest: lighten(selectedTheme.palette.info.main, 0.8),
      warning: selectedTheme.palette.warning.main,
      warning_dark: selectedTheme.palette.warning.dark,
      warning_light: selectedTheme.palette.warning.light,
      warning_lightest: lighten(selectedTheme.palette.warning.main, 0.8),
      error: selectedTheme.palette.error.main,
      error_dark: selectedTheme.palette.error.dark,
      error_light: selectedTheme.palette.error.light,
      error_lightest: lighten(selectedTheme.palette.error.main, 0.8),
    },
    fontFaces: {
      ...legacyTheme.fontFaces,
      default: selectedTheme.typography.fontFamily,
    },
  }

  return (
    <ThemeProvider theme={customisedMuiTheme}>
      {/* @ts-expect-error */}
      <LegacyThemeProvider theme={customisedLegacyTheme}>
        {children}
        <CssBaseline />
      </LegacyThemeProvider>
    </ThemeProvider>
  )
}

export default FeedrThemeProvider
