'use client'

import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { apolloInit } from '@/helpers/apollo/ApolloClient'
import appVariables from '@/config'
import FeedrThemeProvider from './Theme'
import { initAmplify } from '@/helpers/amplifyConfig'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import SupportProvider from './SupportProvider'

import(/* webpackChunkName: "@datadog/browser-rum" */ '@datadog/browser-rum').then(
  ({ datadogRum }) => {
    if (appVariables.DATADOG_APPLICATION_ID && appVariables.DATADOG_CLIENT_TOKEN) {
      datadogRum.init({
        applicationId: appVariables.DATADOG_APPLICATION_ID,
        clientToken: appVariables.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'fusion',
        env: appVariables.FEEDR_ENVIRONMENT,
        version: appVariables.COMMIT_SHA,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        enableExperimentalFeatures: ['clickmap'],
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
          /https:\/\/(.*\.)?feedr\.co/,
          /https:\/\/eatfirstdev.com/,
          /https:\/\/eatfirst.caterdesk.dev/,
          /https:\/\/eatfirst.com/,
        ],
      })

      datadogRum.startSessionReplayRecording()
    }
  },
)

const sdkConfig = {
  core: {
    authorizationKey: appVariables.SPLIT_AUTH_KEY,
    key: 'key',
  },
}

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      initAmplify()
    }
  }, [])

  return (
    <SplitFactoryProvider config={sdkConfig}>
      <ApolloProvider client={apolloInit()}>
        <FeedrThemeProvider>
          <SupportProvider>{children}</SupportProvider>
        </FeedrThemeProvider>
      </ApolloProvider>
    </SplitFactoryProvider>
  )
}

export default Providers
