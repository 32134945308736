import { Components, Theme } from '@mui/material'

export const DialogTheme: Components<Theme> = {
  MuiDialogContent: {
    defaultProps: {
      dividers: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      }),
    },
  },
  MuiDialogContentText: {
    defaultProps: {
      color: ({ palette }) => palette.text.primary,
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      }),
    },
  },
}
