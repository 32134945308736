import { Components, inputBaseClasses, TextFieldProps } from '@mui/material'

export const TextFieldTheme: Components = {
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
    variants: [
      {
        props: { 'data-custom-variant': 'rounded' }, // variant does not work for TextField - https://github.com/mui/material-ui/issues/21749#issuecomment-970284499
        style: {
          [`& .${inputBaseClasses.root}`]: {
            borderRadius: '100px',
          },
        },
      },
    ],
  },
}
