import { Components, toggleButtonClasses } from '@mui/material'

export const ToggleButtonTheme: Components = {
  MuiToggleButton: {
    styleOverrides: {
      root: {
        [`&.${toggleButtonClasses.selected}`]: {
          borderColor: 'currentColor',
        },
      },
    },
  },
}
