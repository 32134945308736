import { Components, Theme } from '@mui/material'

export const TooltipTheme: Components<Theme> = {
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.text.primary,
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
}
