import React from 'react'
import { Components, Theme } from '@mui/material'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded'
import ErrorRounded from '@mui/icons-material/ErrorRounded'
import InfoRounded from '@mui/icons-material/InfoRounded'
import WarningRounded from '@mui/icons-material/WarningRounded'

export const AlertTheme: Components<Theme> = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        success: <CheckCircleRounded fontSize="inherit" />,
        info: <InfoRounded fontSize="inherit" />,
        warning: <WarningRounded fontSize="inherit" />,
        error: <ErrorRounded fontSize="inherit" />,
      },
    },
    styleOverrides: {
      filled: ({ ownerState, theme }) => ({
        color: theme.palette[ownerState.severity || 'success'].contrastText,
      }),
    },
  },
  MuiAlertTitle: {
    defaultProps: {
      color: 'inherit',
    },
  },
}
