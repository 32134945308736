import {
  alpha,
  Components,
  stepIconClasses,
  stepLabelClasses,
  Theme,
} from '@mui/material'

export const StepperTheme: Components<Theme> = {
  MuiStepIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`&:not(.${stepIconClasses.completed}):not(.${stepIconClasses.error})`]:
          {
            borderRadius: theme.spacing(8),
            color: alpha(theme.palette.text.primary, 0.04),
            border: `1px solid ${theme.palette.text.disabled}`,
            [`&.${stepIconClasses.active}`]: {
              color: 'transparent',
              borderColor: theme.palette.success.main,
              [`& .${stepIconClasses.text}`]: {
                fill: theme.palette.success.main,
              },
            },
            [`&.${stepIconClasses.completed}`]: {
              border: 'none',
            },
          },
      }),
      text: ({ theme }) => ({
        fill: theme.palette.text.primary,
      }),
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
        [`&.${stepLabelClasses.active}:not(.${stepLabelClasses.error})`]: {
          color: theme.palette.success.main,
        },
        [`&.${stepLabelClasses.disabled}`]: {
          color: theme.palette.text.disabled,
        },
      }),
    },
  },
}
