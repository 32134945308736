import { createTheme } from '@mui/material'
import { AlertTheme } from './components/Alert/theme'
import { ButtonTheme } from './components/Button/theme'
import { SelectTheme } from './components/Select/theme'
import { TextFieldTheme } from './components/TextField/theme'
import { DataGridTheme } from './components/DataGrid/theme'
import { DateAndTimePickersTheme } from './components/DateAndTimePickers/theme'
import { AutocompleteTheme } from './components/Autocomplete/theme'
import { ToggleButtonTheme } from './components/ToggleButton/theme'
import { ContainerTheme } from './components/Container/theme'
import { CardTheme } from './components/Card/theme'
import { AccordionTheme } from './components/Accordion/theme'
import { TooltipTheme } from './components/Tooltip/theme'
import { DialogTheme } from './components/Dialog/theme'
import { StepperTheme } from './components/Stepper/theme'
import { ButtonGroupTheme } from './components/ButtonGroup/theme'
import {
  EatFirstTypographyOptions,
  TypographyOptions,
  TypographyTheme,
} from './components/Typography/theme'
import {
  EatFirstPaletteOptions,
  PaletteOptions,
} from './components/Colour/theme'
import { LinkTheme } from './components/Link/theme'

export const muiTheme = createTheme({
  brand: 'Feedr',
  typography: TypographyOptions,
  palette: PaletteOptions,
  components: {
    ...AccordionTheme,
    ...AlertTheme,
    ...AutocompleteTheme,
    ...ButtonTheme,
    ...ButtonGroupTheme,
    ...CardTheme,
    ...ContainerTheme,
    ...DataGridTheme,
    ...DateAndTimePickersTheme,
    ...DialogTheme,
    ...LinkTheme,
    ...TextFieldTheme,
    ...ToggleButtonTheme,
    ...TooltipTheme,
    ...TypographyTheme,
    ...SelectTheme,
    ...StepperTheme,
  },
})

export const eatFirstMuiTheme = createTheme({
  ...muiTheme,
  brand: 'EatFirst',
  palette: EatFirstPaletteOptions,
  typography: EatFirstTypographyOptions,
})
