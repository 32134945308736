import { populateFieldsWithTranslations } from '@caterdesk/utils--translations'
import { Translation, Language } from '@/generated/graphql'

export const getLanguage = (): Language => {
  const localeRegex = /^\/(([a-zA-Z0-9.-]+.[a-zA-Z]{2,})\/)?([a-z]{2}-[a-z]{2})\//
  if (typeof window !== 'object') return 'en' as Language
  const locale = localeRegex.exec(window.location.pathname)?.[3] ?? ''

  const language = locale?.split('-')[0] ?? 'en'
  return language as Language
}

export const getTranslationForField = (
  field: string,
  currentValue: string,
  translations: Translation[],
) => {
  const language = getLanguage()
  const translated = populateFieldsWithTranslations(
    {
      [field]: currentValue,
      translations: [...translations],
    },
    language,
  )
  return translated[field]
}
