import {
  alpha,
  Components,
  iconButtonClasses,
  tablePaginationClasses,
  Theme,
} from '@mui/material'

export const DataGridTheme: Components<Theme> = {
  MuiDataGrid: {
    defaultProps: {
      autoHeight: true,
      pageSize: 100,
    },
    styleOverrides: {
      root: ({ theme }) => {
        const { palette } = theme
        return {
          backgroundColor: palette.background.paper,
          '& .MuiDataGrid-columnHeaderTitle': {
            textTransform: 'uppercase',
          },
          '& .MuiDataGrid-row': {
            transition: 'background-color 200ms ease-in-out',
            '&:hover, &.Mui-hovered': {
              backgroundColor: alpha(palette.primary.light, 0.5),
              '@media (hover: none)': {
                backgroundColor: 'transparent',
              },
            },
          },
        }
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`& .${tablePaginationClasses.actions} .${iconButtonClasses.root}:not(.${iconButtonClasses.disabled})`]:
          {
            color: theme.palette.action.active,
          },
      }),
    },
  },
}
